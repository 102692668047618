import { IFileUploader } from './types';
import { getAttachmentUploadUrl, putAttachment } from '../api/api';

export class FileUploader implements IFileUploader {
  private assignmentId: string;
  private uploadUrl: string;
  file: File | null;
  fileName: string;
  status: 'idle' | 'preparing' | 'prepared' | 'uploading' | 'done';

  constructor(assignmentId: string) {
    this.assignmentId = assignmentId;
    this.uploadUrl = '';
    this.file = null;
    this.fileName = '';
    this.status = 'idle';
  }

  async prepareFile(file: File) {
    try {
      this.status = 'preparing';

      const data = await getAttachmentUploadUrl(this.assignmentId, file);
      this.uploadUrl = data.uploadUrl;
      this.fileName = file.name;
      this.file = file;
      this.status = 'prepared';

      return `${data.bucketUrl}/${data.key}`;
    } catch (err) {
      this.status = 'idle';

      console.error(err);
      throw new Error(
        'Something went wrong while getting the file upload data.'
      );
    }
  }

  async uploadFile() {
    if (!this.file) return;

    try {
      this.status = 'uploading';

      await putAttachment(this.uploadUrl, this.file);
      this.status = 'done';
    } catch (err) {
      this.status = 'idle';

      console.error(err);
      throw new Error('Something went wrong while uploading the file.');
    }
  }

  clear() {
    this.status = 'idle';
    this.file = null;
    this.fileName = '';
    this.uploadUrl = '';
  }
}
