import React, { FC } from 'react';
import { IGenericModal, useTabProps } from 'asu-sim-toolkit';
import classNames from 'classnames';
import { Button, buttonPrimaryClasses } from '../Button/Button';
import { StudentAccessLevel } from '../../stores/domain';

interface IAccessLevelModalProps extends IGenericModal<boolean> {
  studentAccessLevel: StudentAccessLevel;
}

export const AccessLevelModal: FC<IAccessLevelModalProps> = ({
  studentAccessLevel,
  setOverlayClickHandler,
  onResolve,
}) => {
  setOverlayClickHandler(() => onResolve(false));

  const getTabProps = useTabProps();

  return (
    <div className="bg-white rounded-md shadow-outer flex flex-col overflow-hidden w-[450px] max-w-[100vw]">
      <div className="font-semibold bg-blue text-white p-md">
        Student Access Level
      </div>
      <div className="text-md p-md">
        <div>
          <div className="mb-2">You can:</div>
          <ul>
            {studentAccessLevel !== StudentAccessLevel.Full && (
              <>
                <li>Add text to instructor-made-sections</li>
                <li>Add sections to instructor-made tabs</li>
              </>
            )}
            {studentAccessLevel === StudentAccessLevel.Advanced && (
              <li>Make and edit new tabs</li>
            )}
            {studentAccessLevel === StudentAccessLevel.Full && (
              <li>Make new tabs and edit all tabs</li>
            )}
          </ul>
        </div>
      </div>
      <div className="flex gap-md p-md border-t border-gray-200 justify-end">
        <Button
          className={classNames(buttonPrimaryClasses, 'min-w-[100px]')}
          onClick={() => onResolve(true)}
          {...getTabProps()}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};
