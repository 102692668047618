import {
  IAttachmentUploadUrlResponse,
  TOOL_ID,
  POST_ATTACHMENT_URL,
  API_KEY,
} from './config';

export const getAttachmentUploadUrl = async (
  assignmentId: string,
  file: File
): Promise<IAttachmentUploadUrlResponse> => {
  const payload = {
    toolId: TOOL_ID,
    assignmentId: assignmentId,
    filename: file.name,
    mime: file.type,
  };

  const response = await fetch(POST_ATTACHMENT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
    body: JSON.stringify(payload),
  });

  return response.json();
};

export const putAttachment = async (url: string, file: File) =>
  fetch(url, {
    method: 'PUT',
    body: file,
  });
