import React from 'react';
import { observer } from 'mobx-react-lite';
import { Header } from '../../components/Header/Header';
import { TextInput } from '../../components/TextInput/TextInput';
import { Textarea } from '../../components/Textarea/Textarea';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { useStores } from '../../providers/store/use-stores';
import { FlatAssignmentConfig, StudentAccessLevel } from '../../stores/domain';
import { Button, buttonSecondaryClasses } from '../../components/Button/Button';
import { InstructorViewSection } from './InstructorViewSection';
import { NumberInput } from '../../components/NumberInput/NumberInput';
import { TabsManager } from '../../components/TabsManager/TabsManager';

export const InstructorView = observer(() => {
  const {
    assignmentConfigStore: {
      currentConfig: { data, title, summary, isResubmit },
      plan,
      discard,
      isChanged,
      save,
      updateData,
    },
  } = useStores();

  const updateField =
    <T extends keyof FlatAssignmentConfig>(key: T) =>
    (newValue: FlatAssignmentConfig[T]) =>
      updateData({ [key]: newValue });

  const handleAccessLevelChange = (v: StudentAccessLevel) => {
    updateData({ studentAccessLevel: v });
  };

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <Header title={title || 'Configure assignment'}>
        <div className="flex gap-3">
          <Button
            className={buttonSecondaryClasses}
            onClick={discard}
            disabled={!isChanged}
          >
            Discard
          </Button>
          <Button onClick={save} disabled={!isChanged}>
            Save
          </Button>
        </div>
      </Header>
      <div className="overflow-y-auto">
        <InstructorViewSection title="Basic Assignment Details">
          <TextInput
            label="Title"
            onChange={updateField('title')}
            value={title}
          />
          <Textarea
            label="Summary"
            onChange={updateField('summary')}
            value={summary || ''}
            required={false}
          />
          <Checkbox
            label="Allow students to resubmit their task"
            checked={isResubmit}
            onChange={(v) => updateData({ isResubmit: v })}
          />
        </InstructorViewSection>
        <div className="bg-gray-20 rounded-md p-2 m-6 flex flex-col md:flex-row">
          <InstructorViewSection
            title="Score Settings"
            containerClassName="flex-1"
            contentClassName="p-4 mt-4 rounded-md bg-gray-200"
          >
            <div className="flex flex-col gap-md my-2">
              <NumberInput
                value={data.maxScore}
                description="Maximum score for the assignment"
                onChange={(v) => updateData({ maxScore: v })}
              />
              <NumberInput
                value={data.minWords}
                description="Required minimum word count in text section(s)"
                onChange={(v) => updateData({ minWords: v })}
              />
              <NumberInput
                value={data.minChecklistItems}
                description="Required minimum checklist items in checklist section(s)"
                onChange={(v) => updateData({ minChecklistItems: v })}
              />
            </div>
          </InstructorViewSection>
          <InstructorViewSection
            title="Student Access Level"
            containerClassName="flex-1"
            contentClassName="p-4 mt-4 rounded-md bg-gray-200"
          >
            <div className="flex flex-col gap-md">
              <div>
                <h6>Standard</h6>
                <ul className="list-disc ml-10 my-4 text-sm">
                  <li className="mt-2">Add text to faculty-made sections</li>
                  <li className="mt-2">Add sections to faculty-made tabs</li>
                </ul>
              </div>
              <div className="w-full h-[1px] bg-gray-300" />
              <div>
                <h6>Additional</h6>
                <div className="ml-5 my-4">
                  <Checkbox
                    label="Make and edit new tabs"
                    checked={
                      data.studentAccessLevel > StudentAccessLevel.Standard
                    }
                    disabled={
                      data.studentAccessLevel === StudentAccessLevel.Full
                    }
                    onChange={(v) => {
                      handleAccessLevelChange(
                        v
                          ? StudentAccessLevel.Advanced
                          : StudentAccessLevel.Standard
                      );
                    }}
                  />
                  <Checkbox
                    className="mt-2"
                    label="Make new tabs and edit all tabs"
                    checked={
                      data.studentAccessLevel === StudentAccessLevel.Full
                    }
                    onChange={(v) => {
                      handleAccessLevelChange(
                        v
                          ? StudentAccessLevel.Full
                          : StudentAccessLevel.Advanced
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </InstructorViewSection>
        </div>
        <InstructorViewSection containerClassName="py-2">
          <TabsManager plan={plan} />
        </InstructorViewSection>
      </div>
    </div>
  );
});
