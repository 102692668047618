import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';

import { IconButton } from '../IconButton/IconButton';
import { TextInput } from '../TextInput/TextInput';

export interface IChecklistItem {
  value: string;
  checked: boolean;
}

export interface IChecklistItemProps {
  index: number;
  item: IChecklistItem;
  disabled?: boolean;

  onChange(index: number, newItemValue: IChecklistItem): void;
  onDelete?(index: number): void;
}

export const ChecklistItem = ({
  index,
  item,
  disabled = false,
  onDelete,
  onChange,
}: IChecklistItemProps) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(
    !item.value || disabled
  );
  const [itemValue, setItemValue] = useState<string>(item.value || '');

  useEffect(() => {
    if (isEditMode && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  }, [isEditMode]);

  const handleChange = (v: string) => {
    setItemValue(v);
  };

  const handleCheck = (checked: boolean) => {
    onChange(index, {
      value: itemValue,
      checked,
    });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleBlur();
    }
  };

  const handleBlur = () => {
    if (itemValue !== '') {
      onChange(index, {
        value: itemValue,
        checked: item.checked,
      });
      setIsEditMode(false);
    }
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(index);
    }
  };

  return (
    <div className="flex w-full gap-1 items-center relative">
      <div className="flex w-full items-center">
        <Checkbox
          disabled={disabled || item.value === ''}
          label={!isEditMode ? item.value : ''}
          checked={item.checked}
          onChange={handleCheck}
          className="w-auto"
        />
        {isEditMode && (
          <TextInput
            inputRef={textFieldRef}
            value={itemValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder="Enter new checklist item..."
            className="flex-1 w-full"
            inputClassName="border-none rounded-md text-sm py-1 px-2"
            disabled={disabled}
          />
        )}
      </div>
      {!isEditMode && (
        <div className="flex">
          <IconButton
            iconId="pencil"
            id={`checklist-item-edit-${index}`}
            label="Edit item"
            onClick={handleEdit}
          />
          <IconButton
            iconId="close"
            id={`checklist-item-delete-${index}`}
            label="Delete item"
            onClick={handleDelete}
          />
        </div>
      )}
    </div>
  );
};
