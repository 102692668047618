import { CapiBoundStore, ICAPI } from 'asu-sim-toolkit';
import { ICapiModel } from '../capi';
import { IAssignmentConfigStore, IPlanStore, IRootStore } from './types';
import {
  FlatAssignmentConfig,
  IAssignmentConfigData,
  IToolSpecificAssignmentConfig,
  StudentAccessLevel,
} from './domain';
import { action, makeObservable, observable, reaction } from 'mobx';
import { isDebugMode } from '../config';
import { PlanStore } from './plan-store';
import { EMPTY_DATA, mapDataFromCapi, mapDataToCapi } from './helpers';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';

const SIMULATE_INIT = false;

const EMPTY_CONFIG: IAssignmentConfigData = {
  title: '',
  summary: '',
  isResubmit: false,
  data: EMPTY_DATA,
};

class AssignmentConfig extends CapiBoundStore<ICapiModel> {
  title: string;
  summary?: string;
  isResubmit: boolean;
  data: IToolSpecificAssignmentConfig;

  constructor(
    capi: ICAPI<ICapiModel>,
    initialConfig: IAssignmentConfigData,
    synchronize: 'twoWay' | 'fromCAPI'
  ) {
    super(capi);

    this.title = initialConfig.title;
    this.summary = initialConfig.summary;
    this.isResubmit = initialConfig.isResubmit;
    this.data = initialConfig.data;

    makeObservable(this, {
      title: observable,
      summary: observable,
      isResubmit: observable,
      data: observable,
    });

    if (synchronize === 'twoWay') {
      this.bindToCapi('title', 'Sim.Configuration.Assignment.Title');
      this.bindToCapi('summary', 'Sim.Configuration.Assignment.Summary');
      this.bindToCapi('isResubmit', 'Sim.Configuration.Assignment.Resubmit');
      this.bindToCapi(
        'data',
        'Sim.Configuration.Assignment.Data',
        mapDataFromCapi,
        mapDataToCapi
      );
    }

    if (synchronize === 'fromCAPI') {
      this.synchronizeFromCapi('title', 'Sim.Configuration.Assignment.Title');
      this.synchronizeFromCapi(
        'summary',
        'Sim.Configuration.Assignment.Summary'
      );
      this.synchronizeFromCapi(
        'isResubmit',
        'Sim.Configuration.Assignment.Resubmit'
      );
      this.synchronizeFromCapi(
        'data',
        'Sim.Configuration.Assignment.Data',
        mapDataFromCapi
      );
    }

    if (isDebugMode && SIMULATE_INIT) {
      setTimeout(() => {
        this.capi.set('Sim.Configuration.Assignment.Title', 'Assignment 001');
        this.capi.set(
          'Sim.Configuration.Assignment.Data',
          'JTI1N0IlMjUyMm1pbldvcmRzJTI1MjIlMjUzQTUwJTI1MkMlMjUyMm1heFNjb3JlJTI1MjIlMjUzQTEwMCUyNTJDJTI1MjJtaW5DaGVja2xpc3RJdGVtcyUyNTIyJTI1M0EzJTI1MkMlMjUyMnN0dWRlbnRBY2Nlc3NMZXZlbCUyNTIyJTI1M0EwJTI1MkMlMjUyMnRhYnMlMjUyMiUyNTNBJTI1NUIlMjU3QiUyNTIybGFiZWwlMjUyMiUyNTNBJTI1MjJOZXclMjUyMFRhYiUyNTIwMSUyNTIyJTI1MkMlMjUyMmRlc2NyaXB0aW9uJTI1MjIlMjUzQSUyNTIyJTI1MjIlMjUyQyUyNTIyc2VjdGlvbnMlMjUyMiUyNTNBJTI1NUIlMjU1RCUyNTdEJTI1NUQlMjU3RA=='
        );
      }, 100);
    }
  }
}

export class AssignmentConfigStore
  extends CapiBoundStore<ICapiModel>
  implements IAssignmentConfigStore
{
  private readonly rootStore: IRootStore;
  currentConfig: AssignmentConfig;
  savedConfig: AssignmentConfig;
  plan: IPlanStore;
  assignmentId = '';

  isChanged = false;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);

    this.rootStore = rootStore;
    this.currentConfig = new AssignmentConfig(capi, EMPTY_CONFIG, 'fromCAPI');
    this.savedConfig = new AssignmentConfig(capi, EMPTY_CONFIG, 'twoWay');
    this.plan = new PlanStore(this.rootStore, EMPTY_DATA.tabs);

    makeObservable(this, {
      isChanged: observable,
      assignmentId: observable,
      plan: observable,

      updateData: action.bound,
      save: action.bound,
      discard: action.bound,
    });

    this.bindToCapi('assignmentId', 'Sim.Configuration.Assignment.Id');

    reaction(
      () => this.plan.isDataChanged,
      (v) => {
        this.isChanged = v;
      }
    );
    reaction(
      () => this.savedConfig.data.tabs,
      (v) => {
        this.plan = new PlanStore(this.rootStore, v);
      },
      {
        fireImmediately: true,
      }
    );
  }

  updateData(changedFields: Partial<FlatAssignmentConfig>) {
    this.isChanged = true;

    if (changedFields.title !== undefined) {
      this.currentConfig.title = changedFields.title;
    }

    if (changedFields.summary !== undefined) {
      this.currentConfig.summary = changedFields.summary;
    }

    if (changedFields.isResubmit !== undefined) {
      this.currentConfig.isResubmit = changedFields.isResubmit;
    }

    this.currentConfig.data = { ...this.currentConfig.data, ...changedFields };
  }

  async save() {
    if (this.assignmentId) {
      const isConfirmed = await this.rootStore.modalStore.modal(
        ConfirmationModal,
        {
          title: 'Be careful!',
          message:
            'The students could already start working on this assignment. Are you sure you want to edit the details of this task?',
          confirmLabel: 'Yes',
          denyLabel: 'Cancel',
        }
      );

      if (!isConfirmed) return;
    }

    if (!this.assignmentId) {
      this.assignmentId = crypto.randomUUID();
    }
    this.savedConfig.title = this.currentConfig.title;
    this.savedConfig.summary = this.currentConfig.summary;
    this.savedConfig.isResubmit = this.currentConfig.isResubmit;
    this.savedConfig.data = {
      ...this.currentConfig.data,
      tabs: this.plan.toTabsData(
        this.currentConfig.data.studentAccessLevel !== StudentAccessLevel.Full
      ),
    };
    this.isChanged = false;
    this.plan.resetIsChangedFlag();
    this.rootStore.notificationStore.addNotification(
      'Assignment configuration has been saved.'
    );
  }

  discard() {
    this.currentConfig.title = this.savedConfig.title;
    this.currentConfig.summary = this.savedConfig.summary;
    this.currentConfig.data = this.savedConfig.data;
    this.plan.reinitialize(this.savedConfig.data.tabs);
    this.plan.resetIsChangedFlag();
    this.isChanged = false;
  }
}
