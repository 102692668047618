import {
  CAPI,
  CAPI_TYPES,
  FullscreenCapiSchema,
  GodModeCapiSchema,
  IFullscreenCapiModel,
  IGodModeCapiModel,
  TCapiSchema,
} from 'asu-sim-toolkit';

import { isDebugMode } from './config';

export interface ICapiModel extends IFullscreenCapiModel, IGodModeCapiModel {
  'Sim.Saved': boolean;
  'Sim.Submitted': boolean;

  'Sim.Configuration.Assignment.Id': string;
  'Sim.Configuration.Assignment.Title': string;
  'Sim.Configuration.Assignment.Summary': string;
  'Sim.Configuration.Assignment.Resubmit': boolean;

  'Sim.Configuration.Assignment.Data': string; // Base64 encoded data

  'Sim.Solution.Data': string; // Base64 encoded data;
}

export const capiSchema: TCapiSchema<ICapiModel> = {
  ...GodModeCapiSchema,
  ...FullscreenCapiSchema,
  'Sim.Fullscreen.Visible': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Saved': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Submitted': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },

  'Sim.Configuration.Assignment.Id': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
      readonly: true,
    },
  },
  'Sim.Configuration.Assignment.Title': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Configuration.Assignment.Summary': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
  'Sim.Configuration.Assignment.Resubmit': {
    initialValue: false,
    options: {
      type: CAPI_TYPES.BOOLEAN,
    },
  },
  'Sim.Configuration.Assignment.Data': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },

  'Sim.Solution.Data': {
    initialValue: '',
    options: {
      type: CAPI_TYPES.STRING,
    },
  },
};

let capi: CAPI<ICapiModel>;

export function getCapi() {
  if (!capi) {
    capi = new CAPI<ICapiModel>(isDebugMode);
  }
  return capi;
}
