import React from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from '../../components/Header/Header';
import { Button, buttonSecondaryClasses } from '../../components/Button/Button';
import { useStores } from '../../providers/store/use-stores';
import { isSafari } from 'asu-sim-toolkit';
import { IconId } from '../../icon-definitions';
import { TabsManager } from '../../components/TabsManager/TabsManager';
import { AssignmentDetailsModal } from '../../components/modals/AssignmentDetailsModal';
import { View } from '../../stores/domain';

export const SolutionView = observer(() => {
  const {
    modalStore,
    assignmentConfigStore: {
      savedConfig,
      savedConfig: { title, summary, isResubmit },
    },
    fullscreenStore: { toggleFullscreen, fullscreenControlState, isFullscreen },
    solutionStore: { plan, progress, changeView },
  } = useStores();

  const viewAssignmentDetails = () =>
    modalStore.modal(AssignmentDetailsModal, {
      title: title,
      progress,
      summary,
    });

  const headerAction =
    fullscreenControlState.isVisible && !isSafari
      ? {
          iconId: (isFullscreen ? 'fullscreen_exit' : 'fullscreen') as IconId,
          disabled: !fullscreenControlState.isEnabled,
          onClick: toggleFullscreen,
        }
      : undefined;

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <Header title={savedConfig.title} action={headerAction}>
        {isResubmit && (
          <Button onClick={() => changeView(View.Edit)}>Edit assignment</Button>
        )}
      </Header>
      <div className="px-6 py-3 overflow-y-auto flex flex-col">
        <p>{summary}</p>
        <div className="w-full mb-2 flex justify-end gap-1">
          <Button
            className={buttonSecondaryClasses}
            onClick={viewAssignmentDetails}
          >
            See assignment details
          </Button>
        </div>
        <TabsManager plan={plan} />
      </div>
    </div>
  );
});
