import React, { FC, useState } from 'react';
import { observer, useObserver } from 'mobx-react-lite';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { Tab as ActiveTab } from '../Tab/Tab';
import { TabButton } from '../TabButton/TabButton';
import { IconButton } from '../IconButton/IconButton';
import { IPlanStore } from '../../stores/types';
import { useStores } from '../../providers/store/use-stores';
import { StudentAccessLevel, View } from '../../stores/domain';

interface ITabsManager {
  plan: IPlanStore;
}

export const TabsManager: FC<ITabsManager> = observer(
  ({ plan: { tabs, setTabs, addTab, removeTab } }) => {
    const {
      assignmentConfigStore: {
        savedConfig: {
          data: { studentAccessLevel },
        },
      },
      solutionStore: { view },
      simContextStore: { mode },
    } = useStores();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const isAddEnabled =
      (view === View.Edit &&
        studentAccessLevel !== StudentAccessLevel.Standard) ||
      mode === 'AUTHOR';

    const changeTab = (id: string) => {
      const tabIndex = tabs.findIndex((t) => t.id === id);

      if (tabIndex > -1) {
        setActiveTabIndex(tabIndex);
      }
    };

    const changeTabLabel = (id: string, v: string) => {
      const tabIndex = tabs.findIndex((t) => t.id === id);

      if (tabIndex > -1) {
        tabs[tabIndex].updateTabData({ label: v });
      }
    };

    const handleDragEnd = (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const tabsCopy = [...tabs];
      const [reorderedItem] = tabsCopy.splice(result.source.index, 1);
      tabsCopy.splice(result.destination.index, 0, reorderedItem);

      const activeTabId = tabs[activeTabIndex].id;
      const tabIndex = tabsCopy.findIndex((t) => t.id === activeTabId);

      setTabs(tabsCopy);
      setActiveTabIndex(tabIndex);
    };

    const activeTab = tabs[activeTabIndex] || tabs[0];

    return (
      <div className="w-full flex flex-col mb-6">
        <div className="flex items-center justify-between gap-sm w-full">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              droppableId="droppable-tab-button-list"
              direction="horizontal"
            >
              {(provided) =>
                useObserver(() => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="min-h-[48px] flex flex-1 items-end h-auto overflow-x-auto overflow-y-clip -mb-[1px]"
                  >
                    {tabs.map(({ label, id, isEditDisabled }, index) => (
                      <TabButton
                        isActive={index === activeTabIndex}
                        index={index}
                        key={id}
                        id={id}
                        onClick={() => changeTab(id)}
                        onRemove={
                          tabs.length > 1 ? () => removeTab(id) : undefined
                        }
                        onLabelChange={(v) => changeTabLabel(id, v)}
                        isEditEnabled={
                          (view === View.Edit &&
                            (studentAccessLevel === StudentAccessLevel.Full ||
                              !isEditDisabled)) ||
                          mode === 'AUTHOR'
                        }
                      >
                        {label}
                      </TabButton>
                    ))}
                  </div>
                ))
              }
            </Droppable>
          </DragDropContext>
          {isAddEnabled && (
            <IconButton
              iconId="plus"
              className="mr-1 border border-gray-300 bg-gray-50"
              onClick={addTab}
            />
          )}
        </div>
        <div className="p-3 rounded-b-md rounded-tr-md border border-gray-500">
          <ActiveTab tab={activeTab} />
        </div>
      </div>
    );
  }
);
