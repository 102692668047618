import React, { FC } from 'react';
import classNames from 'classnames';

import { TextInput } from '../TextInput/TextInput';

interface IInstructionsInputProps {
  value: string;
  placeholder?: string;
  className?: string;
  isEditEnabled?: boolean;

  onChange?(value: string): void;
}

export const InstructionInput: FC<IInstructionsInputProps> = ({
  value,
  placeholder,
  className,
  isEditEnabled,
  onChange,
}) =>
  isEditEnabled || value ? (
    <div
      className={classNames(
        'min-h-[30px] bg-white text-black flex items-center',
        className
      )}
    >
      {onChange && isEditEnabled ? (
        <TextInput
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          className="min-h-[30px] w-full"
          inputClassName="text-black z-10 pl-2 pr-1 py-1"
        />
      ) : (
        <span className="px-2 py-1">{value}</span>
      )}
    </div>
  ) : null;
