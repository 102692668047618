import React, { FC } from 'react';
import classNames from 'classnames';

interface ISwitchInput {
  checked: boolean;
  leftLabel?: string;
  rightLabel?: string;
  disabled?: boolean;
  className?: string;

  onChange(value: boolean): void;
}

export const SwitchInput: FC<ISwitchInput> = ({
  checked,
  leftLabel,
  rightLabel,
  disabled,
  onChange,
  className,
}) => (
  <div className="flex items-center">
    <span className="text-xs mr-2">{leftLabel}</span>
    <label
      className={classNames(
        'relative inline-flex items-center cursor-pointer',
        className
      )}
    >
      <input
        className={classNames('sr-only peer', disabled && 'cursor-not-allowed')}
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        disabled={disabled}
      />
      <div className='w-[34px] h-[18px] bg-gray-200 peer-focus:outline-none peer-focus:ring-2 ring-keyboardCue rounded-full peer peer-checked:after:translate-x-full after:content-[""] after:absolute after:top-[1px] after:left-[1px] after:bg-orange after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all' />
    </label>
    <span className="text-xs ml-2">{rightLabel}</span>
  </div>
);
