import {
  IChecklistSection,
  ISection,
  ITab,
  ITextSection,
  RequestStatus,
  SectionType,
} from './domain';
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx';
import {
  IFileUploader,
  IPlannerChecklistSection,
  IPlannerChecklistSectionItem,
  IPlannerSectionBase,
  IPlannerTab,
  IPlannerTextSection,
  IPlanStore,
  IRootStore,
  PlannerSection,
} from './types';
import { NotificationType } from 'asu-sim-toolkit';
import { FileUploader } from './file-uploader';

const EMPTY_TAB = {
  label: 'New Tab',
  description: '',
  sections: [],
};

const EMPTY_TEXT_SECTION: ITextSection = {
  title: 'New Section Title',
  instructions: '',
  type: SectionType.Text,
  body: '',
  id: '',
};

export class PlannerSectionBase implements IPlannerSectionBase {
  readonly rootStore: IRootStore;
  id: string;
  isEditDisabled: boolean;
  isChanged: boolean;
  title: string;
  instructions: string;
  type: SectionType;
  attachedFile?: string;
  fileRequestStatus: RequestStatus;
  fileUploader: IFileUploader;

  constructor(rootStore: IRootStore, initialConfig: ISection) {
    this.rootStore = rootStore;
    this.id = initialConfig.id || crypto.randomUUID();
    this.isEditDisabled = Boolean(initialConfig.isEditDisabled);
    this.isChanged = false;
    this.title = initialConfig.title;
    this.instructions = initialConfig.instructions;
    this.type = initialConfig.type;
    this.attachedFile = initialConfig.attachedFile;
    this.fileRequestStatus = RequestStatus.Idle;
    this.fileUploader = new FileUploader(
      this.rootStore.assignmentConfigStore.assignmentId
    );

    makeObservable(this, {
      isEditDisabled: observable,
      isChanged: observable,
      title: observable,
      instructions: observable,
      attachedFile: observable,
      type: observable,
      fileRequestStatus: observable,

      updateSectionData: action.bound,
      updateAttachment: action.bound,
      clearAttachedFile: action.bound,
    });
  }

  updateSectionData(changedFields: Partial<ISection>) {
    if (changedFields.title !== undefined) {
      this.title = changedFields.title;
    }

    if (changedFields.attachedFile !== undefined) {
      this.attachedFile = changedFields.attachedFile;
    }

    if (changedFields.instructions !== undefined) {
      this.instructions = changedFields.instructions;
    }

    this.isEditDisabled = true;
  }

  async updateAttachment(file: File) {
    try {
      this.fileRequestStatus = RequestStatus.Loading;

      const attachedFileUrl = await this.fileUploader.prepareFile(file);

      this.updateSectionData({
        attachedFile: attachedFileUrl,
      });

      this.fileRequestStatus = RequestStatus.Success;
      this.rootStore.notificationStore.addNotification(
        'File uploaded successfully',
        {
          type: NotificationType.success,
        }
      );
    } catch (err) {
      console.error(err);
      this.fileRequestStatus = RequestStatus.Error;
      this.rootStore.notificationStore.addNotification(
        'Something went wrong while uploading the file',
        {
          type: NotificationType.error,
        }
      );
    }
  }

  async clearAttachedFile() {
    this.fileRequestStatus = RequestStatus.Idle;
    this.fileUploader.clear();
    this.updateSectionData({
      attachedFile: '',
    });
  }
}

export class PlannerTextSection
  extends PlannerSectionBase
  implements IPlannerTextSection
{
  type: SectionType.Text;
  body: string;

  constructor(rootStore: IRootStore, initialConfig: ITextSection) {
    super(rootStore, initialConfig);

    this.type = SectionType.Text;
    this.body = initialConfig.body || '';

    makeObservable(this, {
      body: observable,

      updateSectionData: override,
      toJS: action.bound,
    });
  }

  updateSectionData(changedFields: Partial<ITextSection>) {
    if (changedFields.title !== undefined) {
      this.title = changedFields.title;
    }

    if (changedFields.body !== undefined) {
      this.body = changedFields.body;
    }

    if (changedFields.attachedFile !== undefined) {
      this.attachedFile = changedFields.attachedFile;
    }

    if (changedFields.instructions !== undefined) {
      this.instructions = changedFields.instructions;
    }

    this.isChanged = true;
  }

  toJS(isEditDisabled = false) {
    return {
      isEditDisabled: isEditDisabled,
      id: this.id,
      title: this.title,
      instructions: this.instructions,
      type: this.type,
      attachedFile: this.attachedFile,
      body: this.body,
    };
  }
}

export class PlannerChecklistSection
  extends PlannerSectionBase
  implements IPlannerChecklistSection
{
  type: SectionType.Checklist;
  items: IPlannerChecklistSectionItem[];

  constructor(rootStore: IRootStore, initialConfig: IChecklistSection) {
    super(rootStore, initialConfig);

    this.type = SectionType.Checklist;
    this.items = initialConfig.items || [];

    makeObservable(this, {
      items: observable,

      updateSectionData: override,
      toJS: action.bound,
    });
  }

  updateSectionData(changedFields: Partial<IChecklistSection>) {
    if (changedFields.title !== undefined) {
      this.title = changedFields.title;
    }

    if (changedFields.attachedFile !== undefined) {
      this.attachedFile = changedFields.attachedFile;
    }

    if (changedFields.items !== undefined) {
      this.items = changedFields.items;
    }

    if (changedFields.instructions !== undefined) {
      this.instructions = changedFields.instructions;
    }

    this.isChanged = true;
  }

  toJS(isEditDisabled = false) {
    return {
      isEditDisabled: isEditDisabled,
      id: this.id,
      title: this.title,
      instructions: this.instructions,
      type: this.type,
      attachedFile: this.attachedFile,
      items: toJS(this.items),
    };
  }
}

export class PlannerTab implements IPlannerTab {
  readonly rootStore: IRootStore;
  isEditDisabled: boolean;
  isChanged: boolean;
  id: string;
  label: string;
  description: string;
  sections: PlannerSection[];

  constructor(rootStore: IRootStore, initialConfig: ITab) {
    this.rootStore = rootStore;
    this.isChanged = false;
    this.id = initialConfig.id || crypto.randomUUID();
    this.isEditDisabled = Boolean(initialConfig.isEditDisabled);
    this.label = initialConfig.label;
    this.description = initialConfig.description;
    this.sections =
      initialConfig.sections?.map((data) => this.getProperSection(data)) || [];

    makeObservable(this, {
      isEditDisabled: observable,
      isChanged: observable,
      label: observable,
      description: observable,
      sections: observable,

      updateTabData: action.bound,
      addSection: action.bound,
      removeSection: action.bound,
      changeSectionType: action.bound,
      toJS: action.bound,
    });
  }

  private getProperSection(data: ISection) {
    if (data.type === SectionType.Text) {
      return new PlannerTextSection(this.rootStore, data as ITextSection);
    }
    return new PlannerChecklistSection(
      this.rootStore,
      data as IChecklistSection
    );
  }

  updateTabData(changedFields: Partial<ITab>) {
    if (changedFields.label !== undefined) {
      this.label = changedFields.label;
    }

    if (changedFields.description !== undefined) {
      this.description = changedFields.description;
    }

    if (changedFields.sections !== undefined) {
      this.sections = changedFields.sections?.map((data) =>
        this.getProperSection(data)
      );
    }

    this.isChanged = true;
  }

  addSection() {
    this.sections.push(
      new PlannerTextSection(this.rootStore, EMPTY_TEXT_SECTION)
    );

    this.isChanged = true;
  }

  removeSection(id: string) {
    const sectionIndex = this.sections.findIndex((s) => s.id === id);
    if (sectionIndex === -1) return;

    this.sections.splice(sectionIndex, 1);

    this.isChanged = true;
  }

  changeSectionType(id: string, type: SectionType) {
    const sectionIndex = this.sections.findIndex((s) => s.id === id);
    if (sectionIndex === -1) return;

    let newData;
    if (type === SectionType.Text) {
      const sectionItem = this.sections[
        sectionIndex
      ] as IPlannerChecklistSection;
      newData = {
        id,
        title: sectionItem.title,
        attachedFile: sectionItem.attachedFile,
        type: SectionType.Text,
        body: sectionItem.items.map((i) => i.value).join('\n'),
      };
    } else {
      const sectionItem = this.sections[sectionIndex] as IPlannerTextSection;
      newData = {
        id,
        title: sectionItem.title,
        attachedFile: sectionItem.attachedFile,
        type: SectionType.Checklist,
        items: sectionItem.body
          .split(/\r?\n/)
          .map((v) => ({ value: v, checked: false })),
      };
    }

    this.sections[sectionIndex] = this.getProperSection(newData as ISection);

    this.isChanged = true;
  }

  toJS(isEditDisabled = false) {
    return {
      isEditDisabled: isEditDisabled,
      id: this.id,
      label: this.label,
      description: this.description,
      sections: this.sections.map((s) => s.toJS(isEditDisabled)),
    };
  }
}

export class PlanStore implements IPlanStore {
  readonly rootStore: IRootStore;
  isChanged: boolean;
  tabs: IPlannerTab[];

  constructor(rootStore: IRootStore, initialConfig: ITab[]) {
    this.rootStore = rootStore;
    this.isChanged = false;

    this.tabs = initialConfig.map(
      (data) => new PlannerTab(this.rootStore, data)
    );

    makeObservable(this, {
      isChanged: observable,
      tabs: observable,
      isDataChanged: computed,

      removeTab: action.bound,
      addTab: action.bound,
      setTabs: action.bound,
      resetIsChangedFlag: action.bound,
      reinitialize: action.bound,
      toTabsData: action.bound,
    });
  }

  setTabs(tabs: IPlannerTab[]) {
    this.tabs = tabs;
  }

  addTab() {
    this.tabs.push(new PlannerTab(this.rootStore, EMPTY_TAB));

    this.isChanged = true;
  }

  removeTab(id: string) {
    const tabIndex = this.tabs.findIndex((t) => t.id === id);
    if (tabIndex === -1) return;

    this.tabs.splice(tabIndex, 1);

    this.isChanged = true;
  }

  get isDataChanged() {
    return (
      this.isChanged ||
      this.tabs.some((t) => {
        return t.isChanged || t.sections.some((s) => s.isChanged);
      }) === true
    );
  }

  resetIsChangedFlag() {
    this.isChanged = false;
    this.tabs.forEach((t) => {
      t.isChanged = false;
      t.sections.forEach((s) => {
        s.isChanged = false;
      });
    });
  }

  reinitialize(tabs: ITab[]) {
    this.tabs = tabs.map((data) => new PlannerTab(this.rootStore, data));
  }

  toTabsData(isEditDisabled = false) {
    return this.tabs.map((t) => t.toJS(isEditDisabled));
  }
}
