import React, { FC } from 'react';
import ReactQuill from 'react-quill';
import { observer } from 'mobx-react-lite';

import { Icon } from '../Icon';
import { TabSection } from '../TabSection/TabSection';
import { useStores } from '../../providers/store/use-stores';
import { IPlannerTab } from '../../stores/types';
import { StudentAccessLevel, View } from '../../stores/domain';
import { InstructionInput } from '../InstructionInput/InstructionInput';

interface ITabProps {
  tab: IPlannerTab;
}

export const Tab: FC<ITabProps> = observer(
  ({
    tab: {
      id,
      isEditDisabled,
      description,
      updateTabData,
      sections,
      addSection,
      removeSection,
      changeSectionType,
    },
  }) => {
    const {
      simContextStore: { mode },
      assignmentConfigStore: {
        savedConfig: {
          data: { studentAccessLevel },
        },
      },
      solutionStore: { view },
    } = useStores();

    const isEditEnabled =
      (view === View.Edit &&
        (studentAccessLevel === StudentAccessLevel.Full || !isEditDisabled)) ||
      mode === 'AUTHOR';

    const editorPlaceholder =
      mode === 'AUTHOR'
        ? 'Enter instructions or information for your students here...'
        : '';

    return (
      <div className="w-full flex flex-col sm:flex-row gap-6 sm:gap-3">
        <div className="flex-1 min-h-[200px] flex flex-col">
          <InstructionInput
            value="General instructions for this tab"
            className="rounded-t-md font-semibold"
          />
          {isEditEnabled ? (
            <ReactQuill
              key={`editor-${id}`}
              theme="snow"
              value={description}
              onChange={(v) => {
                updateTabData({ description: v });
              }}
              placeholder={editorPlaceholder}
            />
          ) : (
            <div
              className="p-2 flex-1 border border-gray-500 rounded-bl-md text-sm"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <div className="flex-1 w-full flex flex-col">
          {sections.map((s) => (
            <TabSection
              key={s.id}
              section={s}
              removeSection={removeSection}
              changeSectionType={changeSectionType}
            />
          ))}
          {(view === View.Edit || mode === 'AUTHOR') && (
            <button
              className="focus:outline-none focus:ring-2 focus:ring-keyboardCue rounded-md border border-dashed border-gray-600 bg-gray-10 flex justify-center items-center p-2 mb-2"
              onClick={addSection}
            >
              <Icon id="plus" className="mr-2" /> Add Section
            </button>
          )}
        </div>
      </div>
    );
  }
);
