import React, { FC } from 'react';
import classNames from 'classnames';

import { ChecklistItem, IChecklistItem } from './ChecklistItem';
import { MAX_CHECKLIST_ITEMS } from '../../consts';

export interface IChecklistProps {
  list: IChecklistItem[];
  className?: string;
  disabled?: boolean;

  onChange(newList: IChecklistItem[]): void;
}

const EMPTY_ITEM = {
  value: '',
  checked: false,
};

export const Checklist: FC<IChecklistProps> = ({
  list,
  className,
  disabled = false,
  onChange,
}) => {
  const handleItemChange = (index: number, newItemValue: IChecklistItem) => {
    const newList = list.slice();

    newList[index] = newItemValue;
    onChange(newList);
  };

  const handleItemDelete = (index: number) => {
    const newValue = list.slice();

    newValue.splice(index, 1);
    onChange(newValue);
  };

  const extendedValue =
    (list.length > 0 && !list[list.length - 1].value) || disabled
      ? list
      : [...list, EMPTY_ITEM];

  return (
    <div className={classNames('flex flex-col gap-xs', className)}>
      {(list.length < MAX_CHECKLIST_ITEMS ? extendedValue : list).map(
        (item, i) => (
          <ChecklistItem
            key={`item-${i}-${item.value}`}
            item={item}
            index={i}
            onChange={handleItemChange}
            onDelete={i < list.length ? handleItemDelete : undefined}
            disabled={disabled}
          />
        )
      )}
    </div>
  );
};
