import classNames from 'classnames';
import React, { FC } from 'react';

interface ICheckbox {
  checked: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;

  onChange(value: boolean): void;
}

export const Checkbox: FC<ICheckbox> = ({
  label,
  disabled,
  checked,
  onChange,
  className,
}) => (
  <label
    className={classNames(
      'flex items-center',
      className ? className : 'w-full'
    )}
  >
    <input
      className={classNames(
        'mr-2 rounded-md focus:outline-none focus:ring-2 focus:ring-keyboardCue',
        disabled && 'cursor-not-allowed'
      )}
      type="checkbox"
      checked={checked}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      disabled={disabled}
    />
    {label && <span className="text-sm text-black font-normal">{label}</span>}
  </label>
);
