import { IPlannerChecklistSectionItem } from './types';

export interface IBaseAssignmentConfig {
  title: string;
  summary?: string;
  isResubmit: boolean;
}

export interface IAssignmentConfigData extends IBaseAssignmentConfig {
  data: IToolSpecificAssignmentConfig;
}

export type FlatAssignmentConfig = IBaseAssignmentConfig &
  IToolSpecificAssignmentConfig;

// -- tool-specific type definitions --
export interface IToolSpecificAssignmentConfig {
  maxScore: number;
  minWords: number;
  minChecklistItems: number;
  studentAccessLevel: StudentAccessLevel;
  tabs: ITab[];
}

export interface ISolution {
  tabs: ITab[];
}

export interface ISectionBase {
  id: string;
  isEditDisabled?: boolean;
  title: string;
  instructions: string;
  type: SectionType;
  attachedFile?: string;
}

export interface ITextSection extends ISectionBase {
  type: SectionType.Text;
  body: string;
}

export interface IChecklistSection extends ISectionBase {
  type: SectionType.Checklist;
  items: IPlannerChecklistSectionItem[];
}

export type ISection = ITextSection | IChecklistSection;

export interface ITab {
  isEditDisabled?: boolean;
  id?: string;
  label: string;
  description: string;
  sections: ISection[];
}

export enum StudentAccessLevel {
  Standard = 0,
  Advanced = 1,
  Full = 2,
}

export enum SectionType {
  Text = 'text',
  Checklist = 'checklist',
}

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export enum View {
  Edit = 'edit',
  Overview = 'overview',
}
