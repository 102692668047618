import { CapiBoundStore, ICAPI, NotificationType } from 'asu-sim-toolkit';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { ICapiModel } from '../capi';
import {
  IPlanStore,
  IRootStore,
  ISolutionStore,
  PlannerSection,
} from './types';
import { ISolution, ITab, RequestStatus, SectionType, View } from './domain';
import { ConfirmationModal } from '../components/modals/ConfirmationModal';
import { PlanStore } from './plan-store';
import { EMPTY_DATA, mapDataFromCapi, mapDataToCapi } from './helpers';

class Solution extends CapiBoundStore<ICapiModel> implements ISolution {
  tabs: ITab[] = [];

  constructor(
    capi: ICAPI<ICapiModel>,
    initialData: ITab[],
    synchronize?: 'twoWay' | 'fromCAPI'
  ) {
    super(capi);

    this.tabs = initialData;

    makeObservable(this, {
      tabs: observable,

      update: action.bound,
    });

    if (synchronize === 'twoWay') {
      this.bindToCapi(
        'tabs',
        'Sim.Solution.Data',
        mapDataFromCapi,
        mapDataToCapi
      );
    }

    if (synchronize === 'fromCAPI') {
      this.synchronizeFromCapi('tabs', 'Sim.Solution.Data', mapDataFromCapi);
    }
  }

  update(newData: ITab[]) {
    this.tabs = newData;
  }
}

export class SolutionStore
  extends CapiBoundStore<ICapiModel>
  implements ISolutionStore
{
  private rootStore: IRootStore;
  plan: IPlanStore;
  solution: Solution;
  isSaved = true;
  isSubmitted = false;
  view: View;
  requestStatus: RequestStatus;

  constructor(rootStore: IRootStore, capi: ICAPI<ICapiModel>) {
    super(capi);
    this.rootStore = rootStore;

    this.solution = new Solution(capi, EMPTY_DATA.tabs, 'twoWay');
    this.plan = new PlanStore(rootStore, EMPTY_DATA.tabs);
    this.requestStatus = RequestStatus.Idle;
    this.view = View.Edit;

    makeObservable(this, {
      plan: observable,
      isSaved: observable,
      isSubmitted: observable,
      requestStatus: observable,
      view: observable,

      save: action.bound,
      submit: action.bound,
      changeView: action.bound,
      initializePlan: action.bound,

      progress: computed,
      canSubmit: computed,
    });

    this.bindToCapi('isSaved', 'Sim.Saved');
    this.bindToCapi('isSubmitted', 'Sim.Submitted');

    reaction(
      () => this.plan.isDataChanged,
      (v) => {
        this.isSaved = !v;
      }
    );
  }

  initializePlan() {
    const tabs =
      this.solution.tabs.length > 0
        ? this.solution.tabs
        : this.rootStore.assignmentConfigStore.savedConfig.data.tabs;

    this.plan = new PlanStore(this.rootStore, tabs);
  }

  async save() {
    this.solution.update(this.plan.toTabsData());
    this.isSaved = true;
    this.plan.resetIsChangedFlag();

    try {
      this.requestStatus = RequestStatus.Loading;

      const allSections = this.plan.tabs.reduce((p: PlannerSection[], c) => {
        const sections = c.sections.filter(
          (s) => !s.fileUploader.fileName || s.fileUploader.status !== 'done'
        );

        if (sections) {
          return [...p, ...sections];
        }
        return p;
      }, []);

      await Promise.all(allSections.map((s) => s.fileUploader.uploadFile()));

      this.requestStatus = RequestStatus.Success;
      if (!this.isSubmitted) {
        this.rootStore.notificationStore.addNotification(
          'Your homework is saved. You can continue editing or close this window and come back later.'
        );
      }
    } catch (err) {
      console.log(err);
      this.requestStatus = RequestStatus.Error;
      this.rootStore.notificationStore.addNotification(
        'Your work is saved, but something went wrong while saving your attachments.',
        {
          type: NotificationType.error,
        }
      );
    }
  }

  async submit() {
    const {
      notificationStore,
      assignmentConfigStore: {
        savedConfig: { isResubmit },
      },
    } = this.rootStore;
    const isConfirmed = await this.rootStore.modalStore.modal(
      ConfirmationModal,
      {
        title: 'Are you sure?',
        message: isResubmit
          ? 'Once submitted, you will be able to make additional edits to your assignment.'
          : 'Once submitted, you cannot go back to make additional edits to your assignment.',
        confirmLabel: 'Submit',
        denyLabel: 'Cancel',
      }
    );

    if (!isConfirmed) return;

    this.isSubmitted = true;
    this.save();
    this.changeView(View.Overview);

    notificationStore.addNotification(
      'Your work is submitted. You cannot edit your assignment anymore.'
    );
  }

  changeView(view: View) {
    this.view = view;
  }

  get progress() {
    const progressArray = this.plan.tabs.reduce<boolean[]>((acc, curr) => {
      return acc.concat(
        curr.sections.map((s) => {
          if (s.type === SectionType.Text) {
            return Boolean(
              s.body.split(' ').filter(Boolean).length >=
                this.rootStore.assignmentConfigStore.savedConfig.data.minWords
            );
          } else {
            return Boolean(
              s.items.length >=
                this.rootStore.assignmentConfigStore.savedConfig.data
                  .minChecklistItems
            );
          }
        })
      );
    }, []);

    const completedCount = progressArray.filter(Boolean).length;
    const totalCount = progressArray.length;

    return completedCount / totalCount || 0;
  }

  get canSubmit() {
    return this.progress === 1;
  }
}
