import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

import { IconId } from '../../icon-definitions';
import { Icon } from '../Icon';

export interface IIconButtonProps {
  className?: string;
  containerClassName?: string;
  iconId: IconId;
  disabled?: boolean;

  id?: string;
  label?: string;
  disabledLabel?: string;

  onClick(event: MouseEvent<HTMLButtonElement>): void;
  onKeyDown?(event: KeyboardEvent<HTMLButtonElement>): void;
}

export const IconButtonPrimaryClasses =
  'px-[10px] py-[6px] rounded-md text-white bg-orange hover:bg-orange';

export const IconButton: FC<IIconButtonProps> = ({
  className,
  containerClassName,
  iconId,
  onClick,
  onKeyDown,
  disabled = false,
  id,
  label,
  disabledLabel,
}) => (
  <div
    data-tooltip-id={id}
    data-tooltip-content={disabled ? disabledLabel : label}
    className={containerClassName}
  >
    <button
      className={classNames(
        'focus:outline-none focus:ring-2 focus:ring-keyboardCue hover:bg-gray-100 rounded-full min-w-[32px] min-h-[38px] flex items-center justify-center disabled:opacity-50 text-gray-700 text-lg',
        className
      )}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={(e) => {
        if ((e.key === 'Enter' || e.key === ' ') && onKeyDown) {
          onKeyDown(e);
        }
      }}
    >
      <Icon id={iconId} />
    </button>
    {id && label && (
      <Tooltip
        disableStyleInjection
        id={id}
        place="top"
        style={{
          padding: '6px',
          borderRadius: '6px',
          fontSize: '14px',
          maxWidth: '500px',
        }}
      />
    )}
  </div>
);
