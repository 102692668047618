import React, { FC } from 'react';
import { IGenericModal, useTabProps } from 'asu-sim-toolkit';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import classNames from 'classnames';
import { Button, buttonPrimaryClasses } from '../Button/Button';

interface IAssignmentDetailsModalProps extends IGenericModal<boolean> {
  title?: string;
  progress: number;
  summary?: string;
}

export const AssignmentDetailsModal: FC<IAssignmentDetailsModalProps> = ({
  title,
  progress,
  summary,
  setOverlayClickHandler,
  onResolve,
}) => {
  setOverlayClickHandler(() => onResolve(false));

  const getTabProps = useTabProps();

  return (
    <div className="bg-white rounded-md shadow-outer flex flex-col overflow-hidden w-[450px] max-w-[100vw]">
      {title && (
        <div className="font-semibold bg-blue text-white p-md">{title}</div>
      )}
      <div className="text-md p-md">
        <div className="flex flex-col gap-1">
          <div>
            <h6 className="text-lg font-semibold mb-2">Progress</h6>
            <ProgressBar value={progress} total={1} />
          </div>
          {summary && (
            <div>
              <h6 className="text-lg font-semibold mb-2">
                Assignment summary:
              </h6>
              <p>{summary}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-md p-md border-t border-gray-200 justify-end">
        <Button
          className={classNames(buttonPrimaryClasses, 'min-w-[100px]')}
          onClick={() => onResolve(true)}
          {...getTabProps()}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};
