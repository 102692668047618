import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from '../../components/Header/Header';
import { Button, buttonSecondaryClasses } from '../../components/Button/Button';
import { useStores } from '../../providers/store/use-stores';
import { isSafari } from 'asu-sim-toolkit';
import { IconId } from '../../icon-definitions';
import { TabsManager } from '../../components/TabsManager/TabsManager';
import { SolutionView } from '../SolutionView/SolutionView';
import { AssignmentDetailsModal } from '../../components/modals/AssignmentDetailsModal';
import { AccessLevelModal } from '../../components/modals/AccessLevelModal';
import { Spinner } from '../../components/Spinner/Spinner';
import { RequestStatus, View } from '../../stores/domain';

export const StudentView = observer(() => {
  const {
    modalStore,
    assignmentConfigStore: {
      savedConfig,
      savedConfig: {
        title,
        summary,
        data: { studentAccessLevel },
      },
    },
    fullscreenStore: { toggleFullscreen, fullscreenControlState, isFullscreen },
    solutionStore: {
      save,
      submit,
      isSaved,
      isSubmitted,
      view,
      plan,
      initializePlan,
      canSubmit,
      progress,
      requestStatus,
    },
  } = useStores();

  useEffect(() => {
    initializePlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewAccessLevel = () =>
    modalStore.modal(AccessLevelModal, { studentAccessLevel });

  const viewAssignmentDetails = () =>
    modalStore.modal(AssignmentDetailsModal, {
      title: title,
      progress,
      summary,
    });

  const headerAction =
    fullscreenControlState.isVisible && !isSafari
      ? {
          iconId: (isFullscreen ? 'fullscreen_exit' : 'fullscreen') as IconId,
          disabled: !fullscreenControlState.isEnabled,
          onClick: toggleFullscreen,
        }
      : undefined;

  if (view === View.Overview) {
    return <SolutionView />;
  }

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <Header title={savedConfig.title} action={headerAction}>
        <div className="flex gap-3">
          {requestStatus === RequestStatus.Loading ? (
            <div className="h-full mx-2 my-0.5">
              <Spinner />
            </div>
          ) : isSubmitted ? (
            <Button onClick={submit} disabled={!canSubmit}>
              Resubmit
            </Button>
          ) : (
            <>
              <Button onClick={save} disabled={isSaved}>
                Save
              </Button>
              <Button onClick={submit} disabled={!canSubmit}>
                Submit
              </Button>
            </>
          )}
        </div>
      </Header>
      <div className="px-6 py-3 overflow-y-auto flex flex-col">
        <div className="w-full mb-2 flex justify-end gap-1">
          <Button className={buttonSecondaryClasses} onClick={viewAccessLevel}>
            See your access level
          </Button>
          <Button
            className={buttonSecondaryClasses}
            onClick={viewAssignmentDetails}
          >
            See assignment details
          </Button>
        </div>
        <TabsManager plan={plan} />
      </div>
    </div>
  );
});
