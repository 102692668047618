import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useRef,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from '../Icon';
import { IconId } from '../../icon-definitions';
import { Tooltip } from 'react-tooltip';

export interface IHeader extends PropsWithChildren {
  title: string;
  hideFullscreenButton?: boolean;
  leftActions?: ReactElement;
  action?: {
    iconId: IconId;
    disabled?: boolean;
    onClick(): void;
  };
}

export const Header: FC<IHeader> = observer(
  ({ title, children, action, leftActions }) => {
    const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
    const titleRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
      const titleElement = titleRef.current;
      if (
        titleElement &&
        titleElement.scrollHeight > titleElement.clientHeight
      ) {
        setIsTitleOverflowing(true);
      } else {
        setIsTitleOverflowing(false);
      }
    }, [title]);

    return (
      <div className="w-full min-h-[60px] flex bg-blue gap-4 flex-none">
        {leftActions}
        <div className="w-full p-3 flex items-center justify-between gap-4">
          <div className="h-full flex items-center gap-4">
            <h1 className="text-white font-semibold text-xl h-full flex items-center overflow-hidden">
              <span
                className="line-clamp-2"
                ref={titleRef}
                data-tooltip-id="header-title-tooltip"
                data-tooltip-content={title}
              >
                {title}
              </span>
            </h1>
          </div>
          <div className="h-full flex gap-3 items-center">{children}</div>
        </div>
        {action && (
          <button
            className="w-[64px] bg-blue-faded"
            disabled={action.disabled}
            onClick={action.onClick}
          >
            <Icon className="text-xl text-white" id={action.iconId} />
          </button>
        )}
        {isTitleOverflowing && (
          <Tooltip
            id="header-title-tooltip"
            place="bottom"
            disableStyleInjection
            style={{
              padding: '6px',
              borderRadius: '6px',
              fontSize: '14px',
              maxWidth: '500px',
            }}
          />
        )}
      </div>
    );
  }
);
