import { ToolkitIconId } from 'asu-sim-toolkit';

export const iconDefinitions = {
  unlink: {
    viewBox: '0 0 26 28',
    content: `
      <path fill="currentColor" d="M6.859 19.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719zM9.5 20.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM6 17c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM25.75 19c0 1.203-0.469 2.328-1.328 3.172l-2.297 2.281c-0.844 0.844-1.969 1.297-3.172 1.297s-2.344-0.469-3.187-1.328l-5.219-5.234c-0.266-0.266-0.469-0.562-0.656-0.875l3.734-0.281 4.266 4.281c0.562 0.562 1.563 0.578 2.125 0.016l2.297-2.281c0.281-0.281 0.438-0.656 0.438-1.047 0-0.406-0.156-0.781-0.438-1.062l-4.281-4.297 0.281-3.734c0.313 0.187 0.609 0.391 0.875 0.656l5.25 5.25c0.844 0.859 1.313 1.984 1.313 3.187zM16.109 7.688l-3.734 0.281-4.266-4.281c-0.281-0.281-0.656-0.438-1.062-0.438s-0.781 0.156-1.062 0.422l-2.297 2.281c-0.281 0.281-0.438 0.656-0.438 1.047 0 0.406 0.156 0.781 0.438 1.062l4.281 4.281-0.281 3.75c-0.313-0.187-0.609-0.391-0.875-0.656l-5.25-5.25c-0.844-0.859-1.313-1.984-1.313-3.187s0.469-2.328 1.328-3.172l2.297-2.281c0.844-0.844 1.969-1.297 3.172-1.297s2.344 0.469 3.187 1.328l5.219 5.234c0.266 0.266 0.469 0.562 0.656 0.875zM26 9c0 0.281-0.219 0.5-0.5 0.5h-5c-0.281 0-0.5-0.219-0.5-0.5s0.219-0.5 0.5-0.5h5c0.281 0 0.5 0.219 0.5 0.5zM17.5 0.5v5c0 0.281-0.219 0.5-0.5 0.5s-0.5-0.219-0.5-0.5v-5c0-0.281 0.219-0.5 0.5-0.5s0.5 0.219 0.5 0.5zM23.859 2.859l-4 4c-0.109 0.094-0.234 0.141-0.359 0.141s-0.25-0.047-0.359-0.141c-0.187-0.203-0.187-0.516 0-0.719l4-4c0.203-0.187 0.516-0.187 0.719 0 0.187 0.203 0.187 0.516 0 0.719z" />
    `,
  },
  pencil: {
    viewBox: '0 0 24 28',
    content: `
     <path fill="currentColor" d="M5.672 24l1.422-1.422-3.672-3.672-1.422 1.422v1.672h2v2h1.672zM13.844 9.5c0-0.203-0.141-0.344-0.344-0.344-0.094 0-0.187 0.031-0.266 0.109l-8.469 8.469c-0.078 0.078-0.109 0.172-0.109 0.266 0 0.203 0.141 0.344 0.344 0.344 0.094 0 0.187-0.031 0.266-0.109l8.469-8.469c0.078-0.078 0.109-0.172 0.109-0.266zM13 6.5l6.5 6.5-13 13h-6.5v-6.5zM23.672 8c0 0.531-0.219 1.047-0.578 1.406l-2.594 2.594-6.5-6.5 2.594-2.578c0.359-0.375 0.875-0.594 1.406-0.594s1.047 0.219 1.422 0.594l3.672 3.656c0.359 0.375 0.578 0.891 0.578 1.422z" />
    `,
  },
  attach: {
    viewBox: '0 0 32 32',
    content: `
     <path fill="currentColor" d="M20.807 10.22l-2.030-2.029-10.15 10.148c-1.682 1.681-1.682 4.408 0 6.089s4.408 1.681 6.090 0l12.18-12.178c2.804-2.802 2.804-7.346 0-10.148-2.802-2.803-7.347-2.803-10.149 0l-12.788 12.787c-0.009 0.009-0.019 0.018-0.027 0.026-3.909 3.909-3.909 10.245 0 14.153 3.908 3.908 10.246 3.908 14.156 0 0.009-0.009 0.016-0.018 0.026-0.027l0.001 0.001 8.729-8.728-2.031-2.029-8.729 8.727c-0.009 0.008-0.018 0.018-0.026 0.026-2.784 2.783-7.312 2.783-10.096 0-2.783-2.783-2.783-7.31 0-10.093 0.010-0.009 0.019-0.018 0.028-0.026l-0.001-0.002 12.79-12.786c1.678-1.679 4.411-1.679 6.090 0s1.678 4.411 0 6.089l-12.18 12.178c-0.56 0.56-1.47 0.56-2.030 0-0.559-0.559-0.559-1.47 0-2.029l10.15-10.149z" />
    `,
  },
  download: {
    viewBox: '0 0 32 32',
    content: `
     <path fill="currentColor" d="M27.844 11.252c-0.101-4.022-3.389-7.252-7.433-7.252-2.369 0-4.477 1.109-5.839 2.835-0.764-0.987-1.959-1.624-3.303-1.624-2.307 0-4.176 1.871-4.176 4.179 0 0.201 0.015 0.399 0.043 0.592-0.351-0.063-0.711-0.098-1.080-0.098-3.344-0-6.054 2.712-6.054 6.058s2.71 6.058 6.054 6.058h2.868l7.078 7.328 7.078-7.328 3.484-0c3.004-0.006 5.438-2.444 5.438-5.451 0-2.565-1.771-4.716-4.156-5.296zM16 26l-6-6h4v-6h4v6h4l-6 6z" />
    `,
  },
};

export type IconId = keyof typeof iconDefinitions | ToolkitIconId;
