import React, { FC } from 'react';

interface ISpinnerProps {
  scale?: number;
}

export const Spinner: FC<ISpinnerProps> = ({ scale = 1 }) => {
  const getSize = (base: number) => Math.round(base * scale);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div
        className="animate-spin inline-block border-current border-t-transparent text-orange rounded-full scale-"
        style={{
          width: getSize(34),
          height: getSize(34),
          borderWidth: getSize(4),
        }}
        role="status"
        aria-label="loading"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
