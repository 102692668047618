import React, {
  FC,
  KeyboardEvent,
  PropsWithChildren,
  useState,
  MouseEvent,
  useRef,
  useEffect,
} from 'react';
import { ITabProps } from 'asu-sim-toolkit';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { IconButton } from '../IconButton/IconButton';
import { TextInput } from '../TextInput/TextInput';
import { Tooltip } from 'react-tooltip';
import { observer } from 'mobx-react-lite';

export interface ITabButtonProps extends PropsWithChildren, ITabProps {
  isActive?: boolean;
  index: number;
  id: string;
  children: string;
  isEditEnabled?: boolean;

  onClick(): void;
  onRemove?(): void;
  onLabelChange(v: string): void;
}

export const TabButton: FC<ITabButtonProps> = observer(
  ({
    children,
    index,
    id,
    isActive = false,
    isEditEnabled = true,
    onClick,
    onRemove,
    onLabelChange,
  }) => {
    const [label, setLabel] = useState(children);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
      if (isEditMode && textFieldRef.current) {
        textFieldRef.current.focus();
        textFieldRef.current.select();
      }
    }, [isEditMode]);

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Enter' || e.code === 'Space') {
        onClick();
      }
    };

    const handleBlur = () => {
      setIsEditMode(false);

      if (label !== '') onLabelChange(label);
    };

    const handleEdit = (
      e: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      setIsEditMode(true);
    };

    const handleDelete = (
      e: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      if (onRemove) onRemove();
    };

    const handleTextInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (e.code === 'Enter' && label !== '') {
        setIsEditMode(false);
        onLabelChange(label);
      }
    };

    return (
      <Draggable draggableId={id} index={index} isDragDisabled={!isEditEnabled}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classNames(
              'first:border-l focus:outline-none focus:border-l-2 focus:border-r-2 focus:border-t-2 focus:border-keyboardCue mt-[3px] select-none cursor-pointer flex items-center justify-center rounded-t-lg text-md z-10 px-3 truncate',
              isActive
                ? 'bg-white py-[6px] min-h-[45px]'
                : 'bg-gray-300 min-h-[41px]',
              isEditMode ? 'min-w-[190px]' : 'min-w-[120px]',
              snapshot.isDragging
                ? 'border-keyboardCue border-2'
                : 'border-gray-500 border-t border-r'
            )}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            role="tab"
            tabIndex={0}
            aria-label="Tab Button"
          >
            {isEditMode ? (
              <TextInput
                inputRef={textFieldRef}
                value={label}
                onChange={setLabel}
                onKeyDown={handleTextInputKeyDown}
                onBlur={handleBlur}
                className="-ml-2  -1 w-[180px]"
              />
            ) : (
              <>
                <h6
                  className="-mb-0.5 truncate inline-block select-none"
                  data-tooltip-id={`tab-${id}`}
                  data-tooltip-content={children}
                >
                  {children}
                </h6>
                <Tooltip
                  id={`tab-${id}`}
                  place="top"
                  style={{
                    padding: '6px',
                    borderRadius: '6px',
                    fontSize: '14px',
                    maxWidth: '500px',
                  }}
                />
                {isEditEnabled && (
                  <IconButton
                    iconId="pencil"
                    className="ml-2"
                    id={`tab-edit-${id}`}
                    label="Edit label"
                    onClick={handleEdit}
                    onKeyDown={handleEdit}
                  />
                )}
                {onRemove && isEditEnabled && (
                  <IconButton
                    iconId="close"
                    id={`tab-delete-${id}`}
                    label="Delete tab"
                    onClick={handleDelete}
                    onKeyDown={handleDelete}
                  />
                )}
              </>
            )}
          </div>
        )}
      </Draggable>
    );
  }
);
