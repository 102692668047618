export const API_KEY = 'super-secret';
export const TOOL_ID = '123';

export const POST_ATTACHMENT_URL =
  'https://2reh3zg2me.execute-api.us-west-2.amazonaws.com/Stage/files';

export interface IAttachmentUploadUrlResponse {
  key: string;
  uploadUrl: string;
  bucketUrl: string;
}
