import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { RequestStatus, SectionType, View } from '../../stores/domain';
import { Textarea } from '../Textarea/Textarea';
import { IconButton } from '../IconButton/IconButton';
import { TextInput } from '../TextInput/TextInput';
import { SwitchInput } from '../SwitchInput/SwitchInput';
import { Checklist } from '../Checklist/Checklist';
import {
  IPlannerChecklistSectionItem,
  PlannerSection,
} from '../../stores/types';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../providers/store/use-stores';
import { Button, buttonSecondaryClasses } from '../Button/Button';
import { Icon } from '../Icon';
import classNames from 'classnames';
import { Spinner } from '../Spinner/Spinner';
import { InstructionInput } from '../InstructionInput/InstructionInput';

interface ITabSectionProps {
  section: PlannerSection;

  removeSection(id: string): void;
  changeSectionType(id: string, type: SectionType): void;
}

export const TabSection: FC<ITabSectionProps> = observer(
  ({ section, removeSection, changeSectionType }) => {
    const {
      id,
      isEditDisabled,
      title,
      instructions,
      type,
      attachedFile,
      updateSectionData,
      updateAttachment,
      clearAttachedFile,
      fileRequestStatus,
      fileUploader,
    } = section;
    const {
      simContextStore: { mode },
      assignmentConfigStore: {
        savedConfig: {
          data: { minWords, minChecklistItems },
        },
      },
      solutionStore: { view },
    } = useStores();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const textTitleFieldRef = useRef<HTMLInputElement>(null);
    const [isTitleEditMode, setIsTitleEditMode] = useState(false);

    const isEditEnabled =
      (view === View.Edit && !isEditDisabled) || mode === 'AUTHOR';

    useEffect(() => {
      if (isTitleEditMode && textTitleFieldRef.current) {
        textTitleFieldRef.current.focus();
        textTitleFieldRef.current.select();
      }
    }, [isTitleEditMode]);

    const handleFileInputClick = () => {
      if (hiddenFileInput?.current) {
        hiddenFileInput.current.click();
      }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = event?.target?.files?.[0];

      if (fileUploaded !== undefined) {
        updateAttachment(fileUploaded);
      }
    };

    const attachmentUrl =
      view === View.Edit && fileUploader.file
        ? URL.createObjectURL(fileUploader.file)
        : attachedFile;

    return (
      <div className="flex flex-col mb-4">
        {isTitleEditMode ? (
          <TextInput
            inputRef={textTitleFieldRef}
            value={title}
            onChange={(v) => {
              updateSectionData({ title: v });
            }}
            onBlur={() => {
              setIsTitleEditMode(false);
            }}
            autoFocus
            inputClassName="z-10 px-2 py-2 rounded-t-md border border-gray-500"
          />
        ) : (
          <div className="p-2 rounded-t-md border-t border-l border-r border-gray-500 flex justify-between items-center">
            <h6 className="font-semibold text-lg">{title}</h6>
            {isEditEnabled && (
              <div className="flex">
                <IconButton
                  iconId="pencil"
                  id={`section-edit-${id}`}
                  label="Edit title"
                  onClick={() => {
                    setIsTitleEditMode(true);
                  }}
                />
                <IconButton
                  iconId="close"
                  id={`section-delete-${id}`}
                  label="Delete section"
                  onClick={() => removeSection(id)}
                />
              </div>
            )}
          </div>
        )}
        <div className="border-x border-gray-500">
          <InstructionInput
            value={instructions}
            placeholder="More details for this section here"
            onChange={(v) => {
              updateSectionData({ instructions: v });
            }}
            isEditEnabled={isEditEnabled}
          />
        </div>
        <div className="rounded-b-md">
          <div className="bg-blue text-white p-2 flex items-center">
            <span className="font-semibold mr-3 text-sm">Section Type: </span>
            {isEditEnabled ? (
              <SwitchInput
                leftLabel="Text"
                rightLabel="Checklist"
                checked={type === SectionType.Checklist}
                onChange={(v) => {
                  changeSectionType(
                    id,
                    v ? SectionType.Checklist : SectionType.Text
                  );
                }}
              />
            ) : (
              <div className="text-sm capitalize">{type}</div>
            )}
          </div>
          {type === SectionType.Text ? (
            <div className="flex flex-col">
              <Textarea
                inputClassName="rounded-b-md border-gray-500 min-h-[100px]"
                value={section.body as string}
                onChange={(v) => {
                  updateSectionData({ body: v });
                }}
                placeholder="Enter text here"
                disabled={view !== View.Edit}
              />
              <div className="mt-1 text-sm text-gray-500 w-full flex justify-between">
                <span>
                  {`Words: ${
                    (section.body as string).split(' ').filter(Boolean).length
                  }`}
                </span>
                <span>{`Required words: ${minWords}`}</span>
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <Checklist
                className="border border-gray-500 rounded-b-md p-2 min-h-[100px]"
                list={section.items as IPlannerChecklistSectionItem[]}
                onChange={(v) => {
                  updateSectionData({ items: v });
                }}
                disabled={view !== View.Edit}
              />
              <div className="mt-1 text-sm text-gray-500">
                {`At least ${minChecklistItems} list items required`}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-2">
          {fileRequestStatus === RequestStatus.Loading ? (
            <div className="h-full ml-2 mr-auto">
              <Spinner />
            </div>
          ) : (
            attachedFile && (
              <div className="mr-auto flex items-center">
                <a
                  className={classNames(
                    buttonSecondaryClasses,
                    'flex items-center rounded-md py-1.5 px-1'
                  )}
                  href={attachmentUrl}
                  title={fileUploader.fileName}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon id="download" className="mr-1 text-lg" />
                  <span
                    className="truncate text-right max-w-[150px]"
                    style={{ direction: 'rtl' }}
                  >
                    {fileUploader.fileName || attachedFile}
                  </span>
                </a>
                {view === View.Edit && (
                  <IconButton
                    iconId="close"
                    onClick={clearAttachedFile}
                    className="text-orange"
                  />
                )}
              </div>
            )
          )}
          {view === View.Edit && !(mode === 'AUTHOR') && !attachedFile && (
            <>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                hidden
              />
              <Button
                className={buttonSecondaryClasses}
                onClick={handleFileInputClick}
              >
                <Icon id="attach" className="mr-1 text-lg" /> Attach file
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
);
