import { Mapper } from 'asu-sim-toolkit';
import { IToolSpecificAssignmentConfig, StudentAccessLevel } from './domain';

export const EMPTY_DATA: IToolSpecificAssignmentConfig = {
  minWords: 50,
  maxScore: 100,
  minChecklistItems: 3,
  studentAccessLevel: StudentAccessLevel.Standard,
  tabs: [
    {
      isEditDisabled: true,
      label: 'New Tab',
      instructions: '',
      description: '',
      sections: [],
    },
  ],
};

export const mapDataToCapi: Mapper<any, string> = (input) =>
  window.btoa(window.unescape(encodeURIComponent(JSON.stringify(input))));

export const mapDataFromCapi: Mapper<string, any> = (input: string) => {
  if (!input) return EMPTY_DATA;

  try {
    return JSON.parse(decodeURIComponent(window.escape(window.atob(input))));
  } catch (e) {
    console.warn('Could not parse assignment config');
    return EMPTY_DATA;
  }
};
