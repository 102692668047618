import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

export interface IInstructorViewSectionProps extends PropsWithChildren {
  title?: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
}

export const InstructorViewSection: FC<IInstructorViewSectionProps> = ({
  title,
  children,
  containerClassName,
  contentClassName,
}) => (
  <div className={classNames('p-6', containerClassName)}>
    {title && <h3 className="font-semibold text-lg">{title}</h3>}
    <div className={classNames('p-3 flex flex-col gap-3', contentClassName)}>
      {children}
    </div>
  </div>
);
