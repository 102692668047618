import React, { FC } from 'react';

interface IProgressBarProps {
  total: number;
  value: number;
}

export const ProgressBar: FC<IProgressBarProps> = ({ total, value }) => {
  const percentage = Math.round((value / total) * 100);

  return (
    <div className="relative w-full h-6 mb-4 rounded-full bg-gray-500">
      <div
        className="h-6 bg-orange rounded-full"
        style={{ width: `${(value / total) * 100}%` }}
      />
      <div className="absolute inset-0 flex justify-center items-center text-white">
        {percentage}%
      </div>
    </div>
  );
};
